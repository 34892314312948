.error-404 {
  margin: 100px 0 300px;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0.3em 2rem rgba(97, 124, 142, 0.5);
}

.error-404-background {
  background-size: cover;
  background-image: url("/images/notFound.png");
 }