/*Use this for overriding bootstrap variables*/
/* Non-Bootstarp CSS variables */
/* Spacing */
/* Misc */
/* Mixins */
.team-bio h1 {
  margin-bottom: 5px; }
.team-bio h3 {
  font-weight: normal;
  font-style: italic;
  margin-bottom: 15px; }
.team-bio hr {
  margin: 30px 0; }
.team-bio img {
  border-radius: 5px;
  margin-bottom: 15px; }
.team-bio .team-bio-image-container {
  height: 350px;
  width: 350px;
  position: relative;
  margin: auto; }
  @media (min-width: 800px) {
    .team-bio .team-bio-image-container {
      height: 327px;
      width: 327px; } }
  @media (min-width: 1080px) {
    .team-bio .team-bio-image-container {
      height: 450px;
      width: 450px; } }
  @media (min-width: 1330px) {
    .team-bio .team-bio-image-container {
      height: 475px;
      width: 475px; } }
  .team-bio .team-bio-image-container:hover .secondary-image {
    opacity: 1.0; }
.team-bio .primary-image {
  height: 100%;
  width: 100%;
  background-size: contain;
  border-radius: 5px; }
.team-bio .secondary-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-size: contain;
  border-radius: 5px;
  transition: opacity ease-out 150ms; }
